.admin {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 0;

    @media (max-width: 500px) {
        width: 100%;
    }

    .message {
        width: calc(min(960px, 100%));
        padding: 16px 0;
        margin: auto;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;

        .icon {
            visibility: hidden;
            background-image: url('../hgv.png');
            display: inline-block;
            vertical-align: middle;
            padding: 2px;
            width: 24px;
            height: 20px;
        }

        &.error {
            color: var(--pink);
            background-color: var(--light_pink);

            .icon {
                visibility: visible;
                background-position: -111px -51px;
                width: 20px;
            }
        }

        &.success {
            color: var(--black);
            background-color: var(--light_green);
            position: relative;

            .icon {
                visibility: visible;
                background-position: -79px -51px;
            }
        }
    }

    .user {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logout {
            padding: 0;
        }
    }

    .toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(960px - 2 * 16px);
        margin: auto;
        padding: 16px;
        z-index: 999;
        background-color: var(--row_color);
        height: 40px;

        .newitem {
            padding: 6px 12px 6px 8px;
        }
    }

    .table-header,
    .table-body {
        width: 960px;
        margin: auto;
    }

    .stuckheader {
        width: 100vw;
        z-index: 99;
    }


    .table-body .row:hover {
        margin: 0;
    }

    .table-body {
        min-height: 0;
    }

    .table-header .row .col,
    .table-body .row .col {
        &:nth-child(1) {
            width: 205px;
        }

        &:nth-child(2) {
            min-width: 160px;
        }

        &:nth-child(3) {
            min-width: 100px;
        }

        &:nth-child(4) {
            min-width: 230px;

            & .th-sort {
                display: none;
            }
        }

        &:nth-child(5) {
            min-width: 65px;
            text-align: right;

            & .th-sort {
                display: none;
            }
        }
    }

    .kibana-iframe {
        width: 100%;
        height: calc(100vh - 200px);
        border: none;
    }
}
