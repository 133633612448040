.login {
    display: flex;
    flex-direction: column;
    width: 545px;
    min-height: 100vh;
    margin: auto;

    @media (max-width: 500px) {
        width: 100%;
    }

    h1 {
        margin-top: 48px;
        margin-bottom: 50px;
    }

    .main-footer {
        margin-top: 80px;
        height: 72px;
        text-align: center;
    }

    .space {
        flex: 1;
    }

    .error {
        background-color: #f3dbde;
        color: #c74d5a;
        padding: 14px;
        margin-bottom: 8px;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;
    }

    form {
        display: grid;
        grid-template-columns: 100px auto;
        grid-gap: 16px;
        align-items: center;
        padding: 16px;

        label {
            display: inline-block;
            width: 100px;
            color: #999;
            text-align: right;
        }

        input {
            border-radius: 3px;
            border: 1px solid #ddd;
            padding: 3px 7px 2px;
            min-height: 21px;
        }
    }
}
