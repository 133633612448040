.editGenomeVariation {
    width: 545px;
    margin: auto;

    @media (max-width: 500px) {
        width: 100%;
    }

    .back-arrow {
        color: var(--alternative_gray);
        position: absolute;
        top: 20px;
        text-decoration: none;
        padding-left: 14px;
    }

    .back-arrow:before {
        content: '';
        background-image: url('../hgv.png');
        background-position: -137px -41px;
        width: 9px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 3px;
    }

    .main-header h1 {
        margin-top: 66px;
        margin-bottom: 50px;
    }

    h4 {
        color: var(--gray);
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        margin: 40px;
    }

    .success-message {
        width: calc(min(960px, 100%));
        padding: 16px 0;
        margin: auto;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;
        color: var(--black);
        background-color: var(--light_green);
        position: relative;

        .icon {
            background-image: url('../hgv.png');
            background-position: -79px -51px;
            display: inline-block;
            vertical-align: middle;
            padding: 2px;
            width: 24px;
            height: 20px;
        }
    }

    .error {
        background-color: #f3dbde;
        color: #c74d5a;
        padding: 14px;
        margin-bottom: 8px;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;
    }

    .fields {
        min-height: 765px;
        transition: all 0.3s ease-in-out;
    }

    .form-field {
        padding: 10px 0;

        @media (max-width: 500px) {
            padding: 10px 14px 6px;

            &:nth-child(2n+1) {
                background: #f5f5f5;
            }
        }
    }

    label {
        color: var(--alternative_gray);
        display: inline-block;
        width: 244px;
    }

    input[type=text],
    textarea {
        width: 282px;
        border-radius: 3px;
        border: 1px solid #ddd;
        padding: 6px 8px 4px 8px;
        min-height: 21px;
        resize: none;

        &.invalid {
            border: 3px solid #f3dbde;
            margin: -2px;
        }
    }

    @media (max-width: 500px) {

        label,
        input[type=text] {
            display: block;
            margin: 0;
            float: none;
            color: #464646;
        }

        label {
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
        }
    }

    .action {
        margin-left: 250px;
        padding: 20px 0;

        @media (max-width: 500px) {
            margin-left: 14px;
        }
    }

    .make-public .disabled {
        color: #999;
    }

    .main-footer {
        margin-top: 80px;
        height: 72px;
        text-align: center;
    }
}
