:root {
    --white: #fff;
    --black: #252525;

    --pink: #c74d5a;
    --light_pink: #f3dbde;

    --gray: #464646;
    --light_gray: #fbfbfb;
    --alternative_gray: #999;

    --green: #A3CD3D;
    --light_green: #edf3d7;

    --row_color: #fbfbfb;
    --alternate_row_color: #f5f5f5;

    --color-layout-tertiary-background: #fafafa;
}

body,
textarea {
    margin: 0;
    font-family: Arial, Helvetica, serif-sans;
    font-size: 13px;
    color: var(--black);
    background-color: var(--row_color);
}

a {
    text-decoration: none;
    color: var(--pink);
}

.link-like {
    color: var(--pink);
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 4px;

    &.tight {
        padding: 0;
    }
}

.gray {
    background-color: var(--gray);
    color: var(--white);
    padding: 7px 14px;
    border-radius: 3px;
    text-decoration: none;
    border-width: 0;
}

h1 {
    font-family: Ebrima, Arial, Helvetica, serif-sans;
    color: #ba6126;
    font-size: 42px;
    font-weight: 400;
    line-height: 0.667;
    text-align: center;
    margin-top: 66px;

    @media (max-width: 500px) {
        margin: 8px auto 24px;
    }
}

.inputbackground {
    background-color: var(--white);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAaCAIAAAD5ZqGGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAChJREFUeNqsw4kJADAMA7Hbf98QCH66RAViZthd7g5J2CYJbX9+AgwAqKdNXMf8LD4AAAAASUVORK5CYII=);
    background-repeat: repeat-x;
}

.wheelbackground {
    width: 15px;
    height: 15px;
    background-image: url(data:image/png;charset=utf-8;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUNJREFUeNp0kr1LA0EUxDeJaJNOsVAJRoOIRVoVFGvbiB8o2NtrEf0HrC20SSWxEoTYXqeidlpZKX6gjWgXCCiiM2FOhnD34Me9fW9md9l7mSiKgkUG/Co/BzPgAsyGhMhaXgBXYBJMgwHV+Z1S/Vq6dnTpmweXYBCcgW7bdESbfqlO3Thoxic3QU25G38sj+s16dvXzqk4YcJjUNTNePKJ9WJdjuY6eASLKh6BJfCkNXsLqgfpWKvTXAbDtvNuSA6vU1+m+RTcWeM+xfxgOfUNmqtgzxpjKeaS5dRvZ/WK3qimmHcsH6WP5nmwaY0VPU7JTuSjLptmiz7+ioYGYwj0aFBWRWe8gW/wQl88YWvgE/Rro0KC8RnMgXfQ67P9ClrgI24gbsA+uNW6T/2W9P+z7eO4AdbBgSarotphx7iGPwEGAMhdQJGTU3aBAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
}

.redbutton {
    background-image: url(data:image/png;charset=utf-8;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAmCAYAAAAFvPEHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFBJREFUeNpsjsEJwDAMA48s0ZWzWOfx32CrSWhNAv2I8yGEAS7M7Ea9qyHRhqNpUa7IcWYUjSCKNhc/btLcA30kyhVJu3s/OCvHCu6ejwADAFxkP5M4jhU5AAAAAElFTkSuQmCC);
    background-repeat: repeat-x;
    background-position: 0 -1px;

    text-align: center;
    font-size: 14px;
    color: #fff;
    text-shadow: 0px -1px 1px #da6465;
    border: 0 none;
    border-radius: 3px;
    width: 80px;
    min-width: 60px;
    height: 36px;
    padding: 0;
}

.page-not-found {
    .message {
        background-color: #f3dbde;
        color: #c74d5a;
        padding: 32px;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;
        width: 300px;
        margin: auto;
    }
}
