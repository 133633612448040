.genomeVariation {
    width: 545px;
    margin: auto;

    @media (max-width: 500px) {
        width: 100%;
    }

    .main-header h1 {
        margin-top: 48px;
        margin-bottom: 50px;
    }

    .fields {
        min-height: 765px;
        transition: all 0.3s ease-in-out;
    }

    .cf:before,
    .cf:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }

    .cf:after {
        clear: both;
    }

    .main-footer {
        margin-top: 80px;
        height: 72px;
        text-align: center;
    }

    .fr-label {
        color: #999;
        float: left;
        width: 250px;
        margin-top: 7px;
    }

    .form-field {
        padding: 10px 0;
    }

    .form-field .input-wrap {
        margin-left: 40px;
        float: left;
        width: 250px;
    }

    .form-field .input-wrap a {
        color: #c74d5a;
        text-decoration: none;
    }

    .form-field .with-top-padding {
        word-break: break-all;
        padding-top: 7px;
    }

    .back-arrow {
        color: var(--alternative_gray);
        position: relative;
        text-decoration: none;
        padding-left: 14px;
    }

    .back-arrow:before {
        content: '';
        background-image: url('../hgv.png');
        background-position: -137px -41px;
        width: 9px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 3px;
    }

    .centered-block {
        text-align: center;
        margin: 40px 0 0;
    }

    @media (max-width: 500px) {
        .genome-variation-wrap {
            width: auto;
            min-width: 300px;
        }

        .main-footer {
            text-align: center;
        }

        .form-field {
            padding: 10px 14px 6px;
        }

        .form-field:nth-child(1) {
            font-style: italic;
        }

        .form-field:nth-child(2n+1) {
            background: #f5f5f5;
        }

        .form-field .fr-label,
        .form-field .input-wrap {
            display: block;
            width: auto;
            margin: 0;
            float: none;
            color: #464646;
        }

        .form-field .fr-label {
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
        }

        .form-field .input-wrap {
            font-size: 18px;
            line-height: 28px;
        }

        .form-field .input-wrap a {
            font-size: 14px;
            display: inline-block;
            line-height: 16px;
        }

        .form-field .styled-select {
            float: none;
            margin: 0;
            width: 236px;
        }

        .form-field .with-top-padding {
            padding-top: 0;
        }
    }
}
