.stuck {
    width: 100%;
    z-index: 100;
}

.partners {
    text-align: center;
    margin: 55px 0 0px;
    padding: 2px 0;
    vertical-align: middle;

    &:before,
    &:after {
        background-image: url('data:image/png;charset=utf-8;base64,iVBORw0KGgoAAAANSUhEUgAAAqcAAAABCAYAAADjPwfeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAOlJREFUeNqclA0KQyEMg6Mn2f0PaQf7ARHTfn0PxvZcmqRROyLipe8zfh9t7+77xDlMbL9d/TRcKmrd2th4w/DfajLdWWhO4Gkk+iePCvwT/gkzqHjVWH+CnSDDfz8D7BfJu9rrWeTpNAX3o+pd8Pyocd4FPHawN21yZ/e7SvXVnFkyc0mmXhvu/8TBFZf1MDUOm60RnhO3AM75jQtHVhPJuwAm837jWA/0V+JpQX8BPWUeF8jF+SY5LtM79ezOQbdPmlvAWpelGnp0j7N7Q3uh57+jL8DvcqlmSjULBLzR/zrz7zN73wIMANlf3RZqP0VaAAAAAElFTkSuQmCC');
        height: 1px;
        width: 679px;
    }
}

.intro {
    font-family: Arial;
    color: #999999;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.556;
    width: 720px;
    margin: 40px auto 28px;
    text-align: center;

    @media (max-width: 500px) {
        display: none;
    }

    a {
        color: var(--pink);
        text-decoration: none;
    }

    em {
        font-size: 11px;
        font-style: normal;
    }
}

.see-more {
    margin: 24px auto 0;
    text-align: center;
}

.mh-background {
    background-image: url('../header-bg.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    position: relative;
    // take no vertical height, it's just a background
    height: 185px;
    margin-top: -185px;
    z-index: -1;

    @media (max-width: 500px) {
        background-size: 200% auto;
    }
}

.above-the-fold {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
